.side-drawer {
  height: 100%;
  background: white;
  box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 20%;
  max-width: 400px;
  z-index: 200;
  transform: translateX(-100%);
  transition: transform 0.3s ease-out;
}

.side-drawer.open {
  transform: translateX(0);
}

/* .side-drawer ul {
  height: 100%;
  list-style: none;
  display: flex;
} */

/* 
@media (min-width:200px){
    .side-drawer{
        display: none
    }
} */