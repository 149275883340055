.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: green;
  height: 56px;
}

.navbar__navigation {
  display: flex;
  height: 100%;
  align-items: center;
  padding: 0 1 rem;
}

.navbar__logo {
  margin-left: 2rem;
}
.navbar__logo a {
  color: white;
  text-decoration: none;
  font-size: 1.5rem;
}

.spacer {
  flex: 1;
}

.navbar_navigation-items ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
}

.navbar_navigation-items li {
  padding: 0 0.5rem;
}

.navbar_navigation-items a {
  color: white;
  text-decoration: none;
}

/* .navbar_navigation-items a:hover,
.navbar_navigation-items a:active {
  color: aqua;
} */
/*
@media (max-width: 400xp) {
  .navbar_navigation-items {
    display: none;
  }
}
@media(min-width: 400px){
    .navbar__toggle-button{
        display: none
    }
    .navbar__logo{
        margin-left: 0
    }
} */
